
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import Layout from "../components/Layout";
import Head from "next/head";
import Hero from "../components/Hero";
import { Flex, Box } from "rebass/styled-components";
import useTranslation from "next-translate/useTranslation";
import FeaturedIn from "../components/home/FeaturedIn";
import Blog from "../components/home/Blog";
import { useIsMobile, withDeviceType } from "../lib/media";
import { SearchProvider } from "../components/search/SearchContext";
import Steps from "../components/home/Steps";
import Faq from "../components/home/Faq";
import { GetCountryServiceTypesDocument, useGetCountryServiceTypesQuery } from "../generated/graphql";
import { addApolloState, initializeApollo } from "../lib/apolloClient";
import { useContext } from "react";
import { LocaleContext } from "../components/locale";
import locationRedirect from "../lib/location-redirect";
import Benefits from "../components/home/Benefits";
import MobileAppFeatures from "../components/home/MobileAppFeatures";
import HomeNumbersGrid from "../components/home/HomeNumbersGrid";
import TestimonialsBlock from "../components/home/TestimonialsBlock";
import SearchBlock from "../components/home/SearchBlock";
import { InferredCountryServiceType } from "../interfaces";
interface Props {
    feed: [
    ];
    query: Record<string, any>;
    deviceType?: string;
    host: string;
    shouldShowCountrySelector: boolean;
}
function Home(props: Props) {
    const { query, deviceType, shouldShowCountrySelector } = props;
    const { t } = useTranslation("home");
    const isMobileViewport = useIsMobile(deviceType);
    const { loading, data } = useGetCountryServiceTypesQuery();
    const countryServiceTypes: InferredCountryServiceType[] = loading
        ? []
        : (data && data.country?.countryServiceTypes) || [];
    // FOR THE DOGLEY BANNER
    const { country: countryCtx } = useContext(LocaleContext);
    const country = countryCtx || ("" as string);
    const showBanner = ["dk", "no", "se"].includes(country);
    const heroVariant = showBanner ? "banner" : "home";
    return (<Layout withTransparentHeader={isMobileViewport ? false : true} title={t("home:title")} displayBannerHome deviceType={deviceType} displayFooterAreas displayCountrySelector={shouldShowCountrySelector} withHrefLang>
      <Head>
        <script type="application/ld+json" dangerouslySetInnerHTML={{
            __html: `{
              "@context": "https://schema.org",
              "@type": "Organization",
              "url": "https://${props.host}",
              "logo": "https://${props.host}/images/logo.svg",
              "sameAs": ["https://www.instagram.com/Gudogdotcom/", "https://www.facebook.com/Gudog/", "https://twitter.com/Gudog"]
            }`
        }}/>
      </Head>
      <Flex>
        <SearchProvider>
          <Box>
            <Hero variant={heroVariant} imgsrc="/images/home/home_desktop.webp" imgAlt={t("home:findTrustedSitters")} darken={0.4}>
              <SearchBlock showBanner={showBanner} countryServiceTypes={countryServiceTypes} isMobileViewport={isMobileViewport}/>
            </Hero>

            <Box>
              <FeaturedIn showTitle={false}/>
              <Steps />
              <Benefits />
              <MobileAppFeatures mobile={isMobileViewport}/>
              <HomeNumbersGrid mobile={isMobileViewport}/>
              <TestimonialsBlock />
              <Faq />
              <Blog />
            </Box>
          </Box>
        </SearchProvider>
      </Flex>
    </Layout>);
}
const getServerSideProps = withDeviceType()(async ({ locale, req, res }) => {
    const shouldShowCountrySelector = locationRedirect(req, res);
    const apolloClient = initializeApollo(locale);
    await apolloClient.query({
        query: GetCountryServiceTypesDocument
    });
    return addApolloState(apolloClient, {
        props: {
            host: req.headers.host,
            locale,
            shouldShowCountrySelector
        }
    });
});
export default Home;

    async function __Next_Translate__getServerSideProps__1916ebe68c0__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__1916ebe68c0__ as getServerSideProps }
  